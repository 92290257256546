<template>
  <div class="lawyer">
    <sideBar :topHeight="515"></sideBar>
    <div class="header">
      <Header :headSelectIndex='3'></Header>
    </div>
    <div class="banner">
      <div>
        <img src="../assets/img_lawyer320.png" alt="">
        <div class="info">
          <img :src="lawyerInfo.MemberPersonalPic" alt="">
          <div class="detail">
            <div class="name">
              <span>{{lawyerInfo.MemberRealName}}</span><span>执业{{lawyerInfo.LawyerPracticeYears}}年</span>
              <span>|</span> {{lawyerInfo.MemberProvince}} {{lawyerInfo.MemberCity}}</div>
            <div class="grade">
              <div>
                <img :src="lawyerInfo.icon" alt="">
              </div>
              <span>{{lawyerInfo.Level}}律师</span>
            </div>
            <div class="good">擅长领域：{{lawyerInfo.ProfessionalField}}</div>
            <div class="three">近30天服务情况</div>
            <div class="three_info">
              <div class="item">
                <img src="../assets/icon_serve.png" alt="">
                <div>服务 {{lawyerInfo.ServiceCountMonth}} 人</div>
              </div>
              <div class="item">
                <img src="../assets/icon_evaluate.png" alt="">
                <div>好评率 {{lawyerInfo.Satisfaction}}</div>
              </div>
              <div class="item">
                <img src="../assets/icon_money.png" alt="">
                <div>送心意 {{lawyerInfo.MindCountMonth}}人次</div>
              </div>
            </div>
            <div class="code">
              <div><img :src="wxer" alt="" v-if="wxer"></div>
              <div>扫码咨询</div>
            </div>
          </div>
        </div>
        <div class="tab">
          <div class="item" :class="tab==1?'active':''" @click="changeTab(1)">服务项目</div>
          <div class="item" :class="tab==2?'active':''" @click="changeTab(2)">律师信息</div>
        </div>
      </div>
    </div>
    <div class="server" v-if="tab==1">
      <div class="item" :class="{'up':hoverIndex==1}" @mouseover="mouseover(1)">
        <img src="../assets/detail_icon_lawyer82.png" alt="">
        <div>私人律师</div>
        <div>￥<span>{{serverInfo[0].ProductCommonPrice}}</span>/月</div>
        <div class="modal" :class="{'animat':hoverIndex==1}" @mouseover="mouseover(1)" @mouseout="hoverIndex=null">
          <div>私人律师</div>
          <div>{{serverInfo[0].ProductDescripiton}}</div>
          <div class="lines"></div>
        </div>
      </div>
      <div class="item" :class="{'up':hoverIndex==2}" @mouseover="mouseover(2)">
        <img src="../assets/detail_icon_photo82.png" alt="">
        <div>图文咨询</div>
        <div>￥<span>{{serverInfo[1].ProductCommonPrice}}</span>/次</div>
        <div class="modal" :class="{'animat':hoverIndex==2}" @mouseover="mouseover(2)" @mouseout="hoverIndex=null">
          <div>图文咨询</div>
          <div>{{serverInfo[1].ProductDescripiton}}</div>
          <div class="lines"></div>
        </div>
      </div>
      <div class="item" :class="{'up':hoverIndex==3}" @mouseover="mouseover(3)">
        <img src="../assets/detail_icon_phone82.png" alt="">
        <div>电话咨询</div>
        <div>￥<span>{{serverInfo[2].ProductCommonPrice}}</span>/次</div>
        <div class="modal" :class="{'animat':hoverIndex==3}" @mouseover="mouseover(3)" @mouseout="hoverIndex=null">
          <div>电话咨询</div>
          <div>{{serverInfo[2].ProductDescripiton}}</div>
          <div class="lines"></div>
        </div>
      </div>
      <div class="item" :class="{'up':hoverIndex==4}" @mouseover="mouseover(4)">
        <img src="../assets/detail_icon_video82.png" alt="">
        <div>视频咨询</div>
        <div>￥<span>{{serverInfo[3].ProductCommonPrice}}</span>/次</div>
        <div class="modal" :class="{'animat':hoverIndex==4}" @mouseover="mouseover(4)" @mouseout="hoverIndex=null">
          <div>视频咨询</div>
          <div>{{serverInfo[3].ProductDescripiton}}</div>
          <div class="lines"></div>
        </div>
      </div>
    </div>
    <div class="lawyer_info" v-if="tab==2">
      <div>
        <div>所在律所：{{lawyerInfo.LawWorkCompanyName}}</div>
        <div>执业证号：{{lawyerInfo.LawIndustryNumber}}</div>
      </div>
      <div>个人简介：{{lawyerInfo.LawyerDesc}}</div>
    </div>
    <div class="title">服务记录</div>
    <div class="record" v-if="ServiceRecord.length>0">
      <div class="item" v-for="(item,index) in ServiceRecord">
        <div class="user">用户 {{item.Moblie}}</div>
        <div class="content">购买了{{item.LawyerName.substring(0,1)}}律师的<span>【{{item.OrderTitle}}】</span>服务</div>
        <div class="time">{{item.Date}}</div>
      </div>

    </div>
    <div class="norecord" v-else>暂无服务记录</div>
    <div class="more" :class="{'active':moreHover==true}" @mouseover="moreOverFun" @mouseleave="moreHover=false"
      v-if="showmore" @click="moreFun">
      <div>更多服务记录</div>
      <img
        :src="moreHover?require('../assets/icon_arrows_up_focused.png'):require('../assets/icon_arrows_up_normal.png')"
        alt="">
    </div>
    <div class="pj">
      <div class="pj_main">
        <div class="title">服务评价</div>
        <div class="list" v-if="CommentList.length>0">
          <div class="item" v-for="(item,index) in CommentList">
            <img src="../assets/icon_head40.png" alt="">
            <div class="info">
              <div class="user">用户 {{item.MemberMoblie}}</div>
              <div class="content">{{item.CommentDes}}</div>
              <div class="good" v-if="item.impress.length>0">
                <div v-for="it in item.impress">{{it}}</div>
              </div>
              <div class="time">{{item.CreateDate}}<span
                  v-if="item.OrderTitle!=null&&item.OrderTitle!=''">【{{item.OrderTitle}}】</span></div>
            </div>
            <div class="grade">
              <div class="star">
                <img src="../assets/icon_shape_selected.png" alt="" v-for="im in item.Stars">
                <img src="../assets/icon_shape_normal.png" alt="" v-for="im in (5-item.Stars)">
              </div>
              <div>{{item.Stars}}.0</div>
            </div>
          </div>

        </div>
        <div class="nolist" v-else>暂无服务评价</div>
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNumPJ" :page-size="pageSizePJ"
          :hide-on-single-page="hidePage" layout=" prev, pager, next,jumper" :total="total" v-if="total>0">
        </el-pagination>
      </div>
    </div>
    <FootBox></FootBox>
  </div>
</template>
<script>
  import Header from '@/components/header'
  import FootBox from '@/components/FootBox'
  import sideBar from '@/components/sideBar'
  import {
    LawyerServiceInfo,
    LawyerProducts,
    CommentListDetail,
    GetLawyerServiceRecord,
    Get_LawyerDetail_WXImg
  } from '@/api/index'
  export default {
    name: 'lawyer',
    data() {
      return {
        menu: '律师推荐',
        tab: 1,
        hoverIndex: null,
        total: 0,
        PageIndex: 1,
        hidePage: true,
        moreHover: false,
        lawyerInfo: {},
        serverInfo: [{}, {}, {}, {}],
        pageNumSer: 1,
        pageSizeSer: 5,
        ServiceRecord: [],
        pageNumPJ: 1,
        pageSizePJ: 5,
        CommentList: [],
        wxer: '',
        showmore: false
      }
    },
    mounted() {
      var data = {
        "lawyerGuid": this.$route.query.lawyerguid
      }
      LawyerServiceInfo(data).then(res => {
        if (res.state == 0) {
          res.data.model.MemberRealName = res.data.model.MemberRealName.substring(0, 1) + '律师'
          var icon = ''
          switch (res.data.model.Level) {
            case '新锐':
              icon = require('../assets/icon_xinrui24.png')
              break;
            case '才俊':
              icon = require('../assets/icon_caijun24.png')
              break;
            case '中坚':
              icon = require('../assets/icon_zhongjian24.png')
              break;
            case '明星':
              icon = require('../assets/icon_mingxin24.png')
              break;
          }
          res.data.model.icon = icon
          if (res.data.model.ServiceCountMonth == 0) {
            res.data.model.ServiceCountMonth = parseInt(Math.random() * 100 + 100)
          }
          if (res.data.model.Satisfaction == '0%') {
            res.data.model.Satisfaction = '100%'
          }
          this.lawyerInfo = res.data.model
          var data = {
            "lawyerThirdId": res.data.model.MemberThirdId
          }
          console.log(data)
          Get_LawyerDetail_WXImg(data).then(res => {
            if (res.state == 0) {
              this.wxer = 'data:image/png;base64,' + res.data
            }
          })
        }
      })
      LawyerProducts(data).then(res => {
        if (res.state == 0) {
          for (var i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].ProductTitle.indexOf('私人律师') >= 0) {
              this.serverInfo[0] = res.data.list[i]
            }
            if (res.data.list[i].ProductTitle.indexOf('文字咨询') >= 0) {
              this.serverInfo[1] = res.data.list[i]
            }
            if (res.data.list[i].ProductTitle.indexOf('电话咨询') >= 0) {
              this.serverInfo[2] = res.data.list[i]
            }
            if (res.data.list[i].ProductTitle.indexOf('视频咨询') >= 0) {
              this.serverInfo[3] = res.data.list[i]
            }
          }
        }
      })
      this.getService()
      this.getPj()
    },
    methods: {
      moreFun() {
        _hmt.push(['_trackEvent', 'click', '更多服务记录', 'pc'])
        this.pageNumSer = this.pageNumSer + 1
        this.getService()
      },
      //服务记录
      getService() {
        var data = {
          "lawyerID": this.$route.query.lawyerguid,
          "pageSize": this.pageSizeSer,
          "pageNum": this.pageNumSer
        }
        GetLawyerServiceRecord(data).then(res => {
          if (res.state == 0) {
            this.ServiceRecord = this.ServiceRecord.concat(res.data.personalAndEntOrder)
            if (res.data.total > this.pageNumSer * this.pageSizeSer) {
              this.showmore = true
            } else {
              this.showmore = false
            }
          } else {

          }
        })
      },
      //服务评价
      getPj() {
        var data = {
          "lawyerGuid": this.$route.query.lawyerguid,
          "pageSize": this.pageSizePJ,
          "pageNum": this.pageNumPJ
        }
        CommentListDetail(data).then(res => {
          if (res.state == 0) {
            var dt = res.data.dt
            for (var i = 0; i < dt.length; i++) {
              dt[i].MemberMoblie = dt[i].MemberMoblie.substring(0, 3) + '****' + dt[i].MemberMoblie.substring(7, 11)
              dt[i].impress = dt[i].Impressions != '' && dt[i].Impressions != null ? dt[i].Impressions.split(' ') :
                []
            }
            this.CommentList = dt
            this.total = res.data.total
          } else {

          }
        })
      },
      changeTab(num) {
        if (num == 1) {
          _hmt.push(['_trackEvent', 'click', '服务项目', 'pc'])
        } else {
          _hmt.push(['_trackEvent', 'click', '律师信息', 'pc'])
        }
        this.tab = num
      },
      mouseover(e) {
        this.hoverIndex = e
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumPJ = val
        this.getPj()
      },
      moreOverFun() {
        this.moreHover = true
      }
    },
    components: {
      Header,
      FootBox,
      sideBar
    }
  }

</script>

<style scoped lang="scss">
  .header {
    width: 100%;
    min-width: 940px;
    background: #21D0C8;
    height: 150px;
  }

  .banner {
    width: 100%;
    height: 320px;
    background: #DEE2EF;

    >div {
      position: relative;
      width: 940px;
      height: 320px;
      margin: 0 auto;

      >img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      .info {
        position: absolute;
        top: 50px;
        left: 80px;
        display: flex;

        >img {
          width: 140px;
          height: 140px;
          background: #FFFFFF;
          border: 4px solid #FFFFFF;
          border-radius: 50%;
          margin-right: 30px;
        }

        .detail {
          flex: 1;
          position: relative;

          .name {
            font-size: 18px;
            color: #666666;
            margin-bottom: 10px;

            >span:nth-child(1) {
              font-size: 38px;
              font-weight: 500;
              color: #333333;
              margin-right: 10px;
            }

            >span:nth-child(2) {
              font-size: 19px;
              color: #666666;
            }

            >span:nth-child(3) {
              font-size: 18px;
              margin: 0 10px;
              position: relative;
              top: -2px;
            }
          }

          .grade {
            width: 140px;
            height: 36px;
            background: #0A0E22;
            border-radius: 20px 18px 18px 0px;
            position: absolute;
            top: -22px;
            left: 124px;

            div {
              width: 54px;
              height: 36px;
              background: #FFFFFF;
              border-radius: 18px 18px 18px 0px;
              display: inline-block;
              vertical-align: middle;

              img {
                position: absolute;
                top: 6px;
                left: 12px;
              }
            }

            span {
              font-size: 18px;
              color: #FFFFFF;
              position: relative;
              top: 2px;
              left: 5px;
            }
          }

          .good {
            font-size: 18px;
            color: #333333;
            margin-bottom: 19px;
          }

          .three {
            font-size: 16px;
            color: #666666;
            margin-bottom: 13px;
          }

          .three_info {
            .item {
              display: inline-block;
              margin-right: 39px;
              font-size: 16px;
              color: #333333;
            }
          }

          .code {
            position: absolute;
            top: 0;
            left: 480px;

            >div:nth-child(1) {
              width: 110px;
              height: 110px;
              background: #FFFFFF;
              border-radius: 20px;

              padding: 10px;
              margin-bottom: 9px;

              img {
                width: 110px;
                height: 110px;
              }
            }

            text-align: center;
            font-size: 16px;
            color: #333333;
          }
        }
      }

      .tab {
        position: absolute;
        bottom: 0;
        left: 250px;

        .item {
          width: 120px;
          height: 40px;
          background: rgba(255, 255, 255, 0);
          border-radius: 10px 10px 0px 0px;
          font-size: 18px;
          color: #666666;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          display: inline-block;
        }

        >.item:nth-child(1) {
          margin-right: 10px;
        }

        .item.active {
          background: rgba(255, 255, 255, 1);
          color: #000000;
        }
      }
    }
  }

  .server {
    width: 940px;
    margin: 0 auto 44px;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    align-items: flex-end;
    height: 200px;

    .item {
      width: 220px;
      height: 180px;
      background: #FFFFFF;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.0800);
      border-radius: 20px;
      text-align: center;
      padding-top: 24px;
      font-size: 16px;
      margin-top: 20px;
      box-sizing: border-box;
      transition: margin 0.5s;
      -webkit-transition: margin 0.5s;
      overflow: hidden;
      position: relative;

      >div:nth-child(2) {
        color: #333333;
        position: relative;
        top: -12px;
      }

      >div:nth-child(3) {
        color: #F24537;

        span {
          font-size: 30px;
        }
      }

      .modal {
        position: absolute;
        bottom: -180px;
        left: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 20px;
        width: 220px;
        height: 180px;
        padding: 20px 18px 10px;
        box-sizing: border-box;
        text-align: left;
        color: #FFFFFF;

        >div:nth-child(1) {
          font-size: 20px;
          margin-bottom: 10px;
        }

        >div:nth-child(2) {
          font-size: 14px;
        }

        .lines {
          width: 220px;
          height: 4px;
          background: #0B9AFB;
          border-radius: 10px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .animat {
        animation: move 0.5s forwards;
      }
    }

    .up {
      margin-bottom: 10px;
      box-shadow: 0px 7px 25px 0px rgba(11, 154, 251, 0.4000);
    }
  }

  .lawyer_info {
    width: 940px;
    height: 180px;
    background: #FFFFFF;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px 30px 0;
    box-shadow: 0px 5px 6px 2px rgb(0 0 0 / 4%);
    margin: 20px auto 44px;
    font-size: 16px;
    color: #000000;
    line-height: 30px;

    >div:nth-child(1) {
      margin-bottom: 10px;

      >div {
        display: inline-block;
      }

      >div:nth-child(1) {
        margin-right: 60px;
      }
    }

  }

  .title {
    width: 940px;
    margin: 0 auto 26px;
    font-size: 24px;
    color: #000000;
  }

  .record {
    width: 940px;
    margin: 0 auto;

    .item {
      padding-bottom: 19px;
      border-bottom: 1px solid #F3F3F3;
      margin-bottom: 20px;

      .user {
        font-size: 12px;
        color: #333333;
        margin-bottom: 10px;
      }

      .content {
        font-size: 18px;
        color: #000000;
        margin-bottom: 5px;

        span {
          color: #21D0C8;
        }
      }

      .time {
        font-size: 12px;
        color: #AAAAAA;
      }
    }

    .item:last-child {
      border-bottom: 0;
    }
  }

  .nolist,
  .norecord {
    text-align: center;
    margin: 100px 0;
    font-size: 16px;
    color: #666666;
  }

  .more {
    width: 100px;
    margin: 30px auto;
    text-align: center;
    cursor: pointer;

    div {
      font-size: 16px;
      color: #666666;
      margin-bottom: 3px;
    }
  }

  .more.active div {
    color: #21D0C8;
  }

  .pj {
    background: #F3F3F3;
    padding: 30px 0;

    .pj_main {
      width: 940px;
      margin: 0 auto;

      .list {
        .item {
          width: 940px;
          background: #FFFFFF;
          border-radius: 20px;
          box-sizing: border-box;
          padding: 20px 30px 30px;
          display: flex;
          position: relative;
          margin-bottom: 20px;

          >img {
            margin-right: 14px;
            width: 40px;
            height: 40px;
          }

          .info {
            flex: 1;
            position: relative;
            top: 11px;

            .user {
              font-size: 12px;
              color: #333333;
              margin-bottom: 10px;
            }

            .content {
              font-size: 18px;
              color: #000000;
              margin-bottom: 11px;
              width: 680px;
            }

            .good {
              margin-bottom: 14px;

              div {
                padding: 1px 10px;
                border: 1px solid #AAAAAA;
                border-radius: 13px;
                font-size: 16px;
                color: #AAAAAA;
                display: inline-block;
                margin-right: 10px;
              }
            }

            .time {
              font-size: 12px;
              color: #AAAAAA;

              span {
                margin-left: 11px;
              }
            }
          }

          .grade {
            padding-top: 10px;

            .star {
              margin-bottom: 14px;

              img {
                margin-right: 3px;
                vertical-align: middle;
              }
            }

            >div:nth-child(2) {
              font-size: 18px;
              color: #333333;
            }
          }
        }
      }
    }
  }

  @keyframes move {
    from {
      bottom: -180px;
    }

    to {
      bottom: 0;
    }
  }

</style>
<style lang="scss">


</style>
